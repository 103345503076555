import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from 'components/Loading';

const LoadingAdornment = ({
    'data-testid': dataTestId = undefined,
    show,
}: {
    show: boolean;
    'data-testid'?: string;
}): React.ReactElement =>
    show ? (
        <InputAdornment data-testid={dataTestId} position="end">
            <Loading small />
        </InputAdornment>
    ) : (
        <></>
    );
export default LoadingAdornment;
