import * as React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link, useLocation } from 'react-router-dom';
import Flex from 'ui/Flex/Flex';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light'
                ? 'rgb(55, 65, 81)'
                : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

type TabT = {
    pathname: string;
    label: string;
};

type Props = {
    tabsList: TabT[];
    keepSearch?: boolean;
    children?: React.ReactNode;
};

export default function NavigatingDropDownMenu({
    tabsList,
    keepSearch = true,
    children,
}: Props) {
    const location = useLocation();
    const theme = useTheme();
    const isTabVisible = (tab: TabT) => location.pathname === tab.pathname;

    const currentTab = tabsList.find(isTabVisible);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Flex justifyContent="flex-end" width="100%" alignItems="center">
            {children}
            <Button
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                size="small"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {currentTab?.label}
            </Button>
            <StyledMenu
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {tabsList.map((tab) => (
                    <Link
                        to={{
                            pathname: tab.pathname,
                            search: keepSearch ? location.search : '',
                        }}
                        key={tab.label}
                    >
                        <MenuItem
                            onClick={handleClose}
                            disableRipple
                            sx={{ color: theme.palette.text.primary }}
                        >
                            {tab.label}
                        </MenuItem>
                    </Link>
                ))}
            </StyledMenu>
        </Flex>
    );
}
