import React from 'react';
import { useLocation } from 'react-router-dom';
import { TabulatedPageTabWithValue } from 'ui/TabulatedPage/TabulatedPage';

// TODO: this hook was previously shared with src/routes/BudgetModule/components/shared/TabulatedChipButtonGroupBudget/hooks.tsx
// We should probably have a single tabulation logic, and compose the components to have either a button, a tab or a chip.

/**
 * This hook is used to know if a tab has already been visited or not. On first render, only the active
 * tab is considered as visited. When the user clicks on a tab, it is considered as visited. We keep track of previously
 * visited tabs in order to keep them in the DOM. This is an optimization to avoid re-rendering the tab content.
 * @param tabsList list of tabs
 * @param keepSearch if true, the search part of the URL is kept in the pathname
 * @return object telling for each tab if it has already been visited or not.
 */
export const useGetVisitedTabs = (
    tabsListWithTabValue: TabulatedPageTabWithValue[],
    activeTabValue: string,
): { [path: string]: boolean } => {
    const visitedTabsInitValue = tabsListWithTabValue.reduce<
        Record<string, boolean>
    >(
        (result, currentTab) => ({
            ...result,
            [currentTab.value]: false,
        }),
        {},
    );
    const [visitedTabs, setVisitedTabs] =
        React.useState<Record<string, boolean>>(visitedTabsInitValue);
    React.useEffect(() => {
        setVisitedTabs((tabs) => ({
            ...tabs,
            [activeTabValue]: true,
        }));
    }, [activeTabValue]);
    return visitedTabs;
};

/**
 * This hook is used to know which tab is active based on the URL.
 * @param tabsListWithTabValue list of tabs
 * @param keepSearch if true, the search part of the URL is kept in the pathname
 * @return active tab value
 */
export const useGetActiveTab = (
    tabsListWithTabValue: TabulatedPageTabWithValue[],
    keepSearch: boolean = false,
): string => {
    const location = useLocation();
    const historySearch = location.search ?? '';
    const tabsSearch = tabsListWithTabValue.reduce(
        (acc, tab) => (tab.search ? [...acc, tab.search] : acc),
        [],
    );
    const search = historySearch || '';
    // if the current location matches the search of one of the tabs, we keep the search part of the URL
    // even if keepSearch is false
    const doesHistorySearchMatchTabSearch = tabsSearch.includes(search);
    // if there is no search in the URL and some tabs do have one, we match the first tab by default
    const shouldMatchFirstTabByDefault = !search && tabsSearch.length > 0;
    if (shouldMatchFirstTabByDefault) {
        return (
            tabsListWithTabValue[0].pathname + tabsListWithTabValue[0].search
        );
    }
    const shouldAppendSearch = keepSearch || doesHistorySearchMatchTabSearch;

    return shouldAppendSearch ? location.pathname + search : location.pathname;
};
