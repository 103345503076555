import TextStyle from '@tiptap/extension-text-style';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        fontSize: {
            setFontSize: (size: React.CSSProperties['fontSize']) => ReturnType;
        };
        lineHeight: {
            setLineHeight: (
                lineHeight: React.CSSProperties['lineHeight'],
            ) => ReturnType;
        };
    }
}

/**
 * We extend TextStyle Extension to add methods to update fontSize and a fontSize attribute.
 * Inspired by : https://stackoverflow.com/a/73742883
 */
export const EnrichedTextStyleExtension = TextStyle.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            fontSize: {
                default: null,
                parseHTML: (element) => element.style.fontSize,
                renderHTML: (attributes) => {
                    if (!attributes.fontSize) {
                        return {};
                    }
                    return {
                        style: `font-size: ${attributes.fontSize}`,
                    };
                },
            },
            lineHeight: {
                default: null,
                parseHTML: (element) => element.style.lineHeight,
                renderHTML: (attributes) => {
                    if (!attributes.lineHeight) {
                        return {};
                    }
                    return {
                        style: `line-height: ${attributes.lineHeight}`,
                    };
                },
            },
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
            setFontSize:
                (fontSize) =>
                ({ commands }) =>
                    commands.setMark(this.name, { fontSize }),
            setLineHeight:
                (lineHeight) =>
                ({ commands }) =>
                    commands.setMark(this.name, { lineHeight }),
        };
    },
});
