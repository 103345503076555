import { isANumber } from './helpers';

export const fractionAggFunc = (params: { values: any[] }): number | null => {
    if (params.values.length === 1) {
        const value = params.values[0];
        return isANumber(value) ? value : null;
    }
    return null;
};

type RealizedRateValue = {
    values: { voted?: number; realized?: number }[];
};

// see docs here: https://www.ag-grid.com/react-grid/aggregation/#example-3---multi-column-aggregation
// This aggFunc is specific to calculate the percent_realized sum.
export const realizedRateAggFunc = (
    params: RealizedRateValue,
): { voted: number; realized: number; toString: () => string } => {
    let votedSum = 0;
    let realizedSum = 0;
    params.values.forEach((value) => {
        if (value?.voted && isANumber(value.voted)) {
            votedSum += value.voted;
        }
        if (value?.realized && isANumber(value.realized)) {
            realizedSum += value.realized;
        }
    });

    const isInvalidRatio = votedSum === 0;
    const rate = isInvalidRatio ? 0 : realizedSum / votedSum;

    return {
        voted: votedSum,
        realized: realizedSum,
        toString: () => rate.toString(),
    };
};
