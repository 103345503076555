import React, { ReactNode, useEffect, useState } from 'react';
import SimpleModal from 'ui/Modals/SimpleModal';
import Button from '@mui/material/Button';
import { Breakpoint, PaperProps as PaperPropsType } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';

type ModalAction = {
    enabled: boolean;
    label: string;
    onClick?: () => void;
    shouldTriggerDefaultOnClose: boolean;
    tooltipMessage?: string;
    className?: string;
};

export type ModalStepT = {
    node: ReactNode;
    confirmAction?: Partial<ModalAction>;
    stepBackAction?: Partial<ModalAction>;
    leftActionGroup?: ReactNode;
    disableClose?: boolean;
    autoClose?: boolean;
    title?: ReactNode;
};

type Props = {
    isOpen?: boolean;
    onClose: () => void;
    maxWidth: Breakpoint;
    isSmallHeight?: boolean;
    showCloseIcon?: boolean;
    steps: ModalStepT[];
    PaperProps?: Partial<PaperPropsType>;
    className?: string;
};

const defaultConfirm: Omit<ModalAction, 'onClick'> = {
    enabled: true,
    label: 'common.next',
    shouldTriggerDefaultOnClose: true,
};

const defaultCancel: Omit<ModalAction, 'onClick'> = {
    enabled: true,
    label: 'common.previous',
    shouldTriggerDefaultOnClose: true,
};

const MultiStepModal = ({
    isOpen = undefined,
    onClose: onCloseProp,
    maxWidth,
    isSmallHeight = undefined,
    showCloseIcon = false,
    steps,
    PaperProps = undefined,
    className = undefined,
}: Props): React.ReactElement => {
    const [stepIndex, setStepIndex] = useState(0);
    const { t } = useTranslation();

    const resetStepIndex = () => {
        const stepsToReset = steps.slice(0, stepIndex).reverse();
        stepsToReset.forEach((s) => {
            if (s.stepBackAction?.onClick !== undefined)
                s.stepBackAction.onClick();
        });
        // TODO: prevent this step index reset when the window.confirm is false
        setStepIndex(0);
    };

    const onClose = () => {
        resetStepIndex();
        onCloseProp();
    };

    const {
        node,
        confirmAction,
        stepBackAction,
        autoClose,
        leftActionGroup,
        disableClose = false,
        title,
    } = steps[stepIndex];

    const mergedConfirmAction: ModalAction = {
        ...defaultConfirm,
        ...confirmAction,
    };
    const mergedStepBackAction: ModalAction = {
        ...defaultCancel,
        ...stepBackAction,
    };

    useEffect(() => {
        if (autoClose && mergedConfirmAction.enabled) {
            setTimeout(() => {
                if (isOpen) {
                    onClose();
                }
            }, 1500);
        }
    }, [mergedConfirmAction.enabled]);

    const onStepCompleted = () => {
        if (mergedConfirmAction.onClick !== undefined)
            mergedConfirmAction.onClick();

        if (stepIndex + 1 < steps.length) {
            setStepIndex(stepIndex + 1);
        } else if (stepIndex + 1 === steps.length) {
            if (mergedConfirmAction.shouldTriggerDefaultOnClose) onClose();
            else resetStepIndex();
        }
    };

    const onStepCanceled = () => {
        if (mergedStepBackAction.onClick !== undefined)
            mergedStepBackAction.onClick();

        if (stepIndex === 0) {
            if (mergedStepBackAction.shouldTriggerDefaultOnClose) onClose();
            else resetStepIndex();
        } else {
            setStepIndex(stepIndex - 1);
        }
    };

    const cancelStepButton = !autoClose && (
        <Tooltip
            title={mergedStepBackAction.tooltipMessage}
            key="cancel-button"
        >
            <span>
                <Button
                    data-testid="e2e-multi-step-modal-cancel-button"
                    disabled={!mergedStepBackAction.enabled}
                    onClick={onStepCanceled}
                >
                    {stepIndex === 0
                        ? t('common.close')
                        : t(mergedStepBackAction.label)}
                </Button>
            </span>
        </Tooltip>
    );

    const completeStepButton = !autoClose && (
        <Tooltip
            title={mergedConfirmAction.tooltipMessage}
            key="complete-button"
        >
            <span>
                <Button
                    data-testid="e2e-multi-step-modal-complete-button"
                    disabled={!mergedConfirmAction.enabled}
                    onClick={onStepCompleted}
                    variant="contained"
                    className={mergedConfirmAction.className}
                >
                    {t(mergedConfirmAction.label)}
                </Button>
            </span>
        </Tooltip>
    );

    return (
        <SimpleModal
            open={isOpen}
            onClose={onClose}
            maxWidth={maxWidth}
            isSmallHeight={isSmallHeight}
            additionalButtons={[cancelStepButton, completeStepButton]}
            leftActionGroup={leftActionGroup}
            showCloseButton={false}
            disableClosing={disableClose}
            dialogTitle={title}
            PaperProps={PaperProps}
            className={className}
            showCloseIcon={showCloseIcon}
        >
            {node}
        </SimpleModal>
    );
};

export default MultiStepModal;
