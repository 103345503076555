import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    SxProps,
    TextField,
    Theme,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { isArray } from 'lodash';
import { AutocompletAble, formatValueForAutocomplete } from './types';
import AutocompletePopper from './AutocompletePopper';

const LABEL_SORT = <T,>(a: AutocompletAble<T>, b: AutocompletAble<T>) =>
    -b.label.localeCompare(a.label);

type Props<T> = {
    selectedValues: AutocompletAble<T>[] | AutocompletAble<T> | null;
    availableValues: AutocompletAble<T>[];
    setSelectedValues: DispatchState<
        AutocompletAble<T | null>[] | AutocompletAble<T | null> | null
    >;
    multiple?: boolean;
    label?: string;
    shouldSortOptions?: boolean;
    sortItems?: (a: AutocompletAble<T>, b: AutocompletAble<T>) => number;
    onClose?: () => void;
    sx?: SxProps<Theme>;
};

const GenericAutocomplete = <T,>({
    availableValues,
    selectedValues,
    setSelectedValues,
    multiple = false,
    label = '',
    shouldSortOptions: sort = false,
    sortItems = LABEL_SORT,
    onClose = () => {},
    sx = { m: '2rem 0' },
}: Props<T>): React.ReactElement => {
    const { t } = useTranslation();

    const getValues = useCallback(
        (
            value:
                | AutocompletAble<T | null>
                | AutocompletAble<T | null>[]
                | null,
        ) => {
            if (isArray(value) && value.find((e) => e.isAll) !== undefined) {
                const candidates = availableValues.filter((e) => !e.disabled);
                if (value.length === candidates.length + 1) return [];
                return candidates;
            }
            return value;
        },
        [availableValues],
    );

    const getOptions = useCallback(
        (options: AutocompletAble<T>[]) => {
            const tOptions = sort ? options.sort(sortItems) : options;
            if (multiple)
                return [
                    formatValueForAutocomplete(
                        null,
                        options.find((e) => !e.disabled) === undefined,
                        t('common.selectAll'),
                        true,
                    ),
                    ...tOptions,
                ];
            return tOptions;
        },
        [multiple],
    );

    const isChecked = useCallback(
        (option: AutocompletAble<T | null>, selected: boolean): boolean => {
            if (selectedValues === null) return false;
            if (!isArray(selectedValues)) return selected;
            if (
                option.isAll &&
                availableValues.filter((e) => !e.disabled).length ===
                    selectedValues.length
            )
                return true;
            return selected;
        },
        [selectedValues, availableValues],
    );

    return (
        <Box sx={sx}>
            <Autocomplete
                multiple={multiple}
                disableClearable={!multiple}
                disableCloseOnSelect={multiple}
                openOnFocus
                openText={t('common.open')}
                closeText={t('common.close')}
                options={getOptions(availableValues)}
                getOptionLabel={(option) => option.label ?? ''}
                getOptionDisabled={(option) => !!option.disabled}
                value={selectedValues || (multiple ? [] : null)}
                limitTags={3}
                PopperComponent={AutocompletePopper}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        color="primary"
                        label={label}
                    />
                )}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                renderOption={(props, id, { selected }) => (
                    <li
                        {...props}
                        style={{ paddingBottom: id.isAll ? '10px' : '0px' }}
                        key={id.label}
                    >
                        {multiple && (
                            <Checkbox
                                icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={isChecked(id, selected)}
                                disabled={id.disabled}
                            />
                        )}
                        {id.label}
                    </li>
                )}
                renderTags={(ids) =>
                    ids.map((id) => (
                        <Chip
                            key={`${id.value}-${id.label}`}
                            color="primary"
                            label={id.label}
                            onDelete={() => {
                                const newIds = ids.filter(
                                    (i) => i.value !== id.value,
                                );
                                setSelectedValues(getValues(newIds));
                            }}
                        />
                    ))
                }
                onChange={(_, ids) => {
                    setSelectedValues(getValues(ids));
                }}
                onClose={onClose}
            />
        </Box>
    );
};

export default GenericAutocomplete;
