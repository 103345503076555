import { Box, BoxProps } from '@mui/material';
import React from 'react';

// shorthand props created for convenience
type FlexProps = {
    direction?: BoxProps['flexDirection'];
    align?: BoxProps['alignItems'];
    justify?: BoxProps['justifyContent'];
    wrap?: BoxProps['flexWrap'];
};

const Flex = (props: BoxProps & FlexProps) => {
    const {
        // shorthand
        direction,
        align,
        justify,
        wrap,
        // BoxProps full length props
        flexDirection,
        alignItems,
        justifyContent,
        flexWrap,
        ...otherProps
    } = props;
    return (
        <Box
            display="flex"
            flexDirection={direction ?? flexDirection ?? 'row'}
            alignItems={align ?? alignItems ?? 'initial'}
            justifyContent={justify ?? justifyContent ?? 'initial'}
            flexWrap={wrap ?? flexWrap ?? 'nowrap'}
            {...otherProps}
        />
    );
};

export default Flex;
