import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import {
    Breakpoint,
    DialogTitle,
    IconButton,
    Dialog,
    PaperProps as PaperPropsType,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    children?: ReactNode;
    open?: boolean;
    onClose: () => void;
    isSmallHeight?: boolean;
    maxWidth?: Breakpoint;
    additionalButtons?: ReactNode[];
    leftActionGroup?: ReactNode;
    showCloseButton?: boolean;
    showCloseIcon?: boolean;
    disableClosing?: boolean;
    dialogTitle?: ReactNode;
    PaperProps?: Partial<PaperPropsType>;
    className?: string;
};

const SimpleModal = ({
    open = true,
    onClose: onCloseProp,
    isSmallHeight = undefined,
    children = null,
    maxWidth = 'sm',
    additionalButtons = [],
    leftActionGroup = [],
    showCloseButton = true,
    showCloseIcon = false,
    disableClosing = false,
    dialogTitle = undefined,
    PaperProps = {},
    className = undefined,
}: Props): React.ReactElement => {
    const { t } = useTranslation();

    // If disableClosing is true:
    // We block MUI native closing feature on click outside modal and escape key.
    const onClose = useCallback(
        (
            event: React.SyntheticEvent<Element, Event>,
            reason: 'escapeKeyDown' | 'backdropClick',
        ) => {
            if (disableClosing && event) {
                if (['backdropClick', 'escapeKeyDown'].includes(reason)) {
                    return;
                }
            }
            onCloseProp();
        },
        [disableClosing],
    );
    const displayCloseButton =
        (!showCloseButton && !disableClosing) || showCloseIcon;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{ paper: isSmallHeight ? 'paper-static-size' : '' }}
            fullWidth
            maxWidth={maxWidth}
            PaperProps={PaperProps}
            className={className}
        >
            {dialogTitle && (
                <DialogTitle variant="h1" color="primary">
                    {dialogTitle}
                </DialogTitle>
            )}
            {children && <DialogContent>{children}</DialogContent>}
            {displayCloseButton && (
                <DialogActions sx={{ position: 'absolute', top: 0, right: 0 }}>
                    <IconButton aria-label="close" onClick={onCloseProp}>
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
            )}
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <div>{leftActionGroup}</div>
                <div>
                    {showCloseButton && !disableClosing && (
                        <Button
                            data-testid="e2e-simple-modal-close-button"
                            onClick={onCloseProp}
                        >
                            {t('common.close')}
                        </Button>
                    )}
                    {additionalButtons}
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default SimpleModal;
