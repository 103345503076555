import { ColDef, GridApi } from 'ag-grid-community';
import { BudgetPhases } from 'business/constants';
import { camelize } from 'helpers/syntaxHelper';

export const isNotANumber = (value: unknown): boolean =>
    typeof value !== 'number' || Number.isNaN(value);
export const isANumber = (value: unknown): value is number =>
    !isNotANumber(value);

export const isFloatParsable = (value: unknown): boolean =>
    typeof value === 'number' ||
    (typeof value === 'string' && /^[0-9.]+$/.test(value));

export const getFiltersFromTCDNode = (
    pivotKeys: string[],
    node: FlowAnyObject,
    gridApi: GridApi,
): QueryFilterT[] => {
    // @ts-expect-error [TS migration] Was not detected by flow
    const totalGroupData = [];

    const pivotColumns = gridApi.getPivotColumns();

    // @ts-expect-error [TS migration] Was not detected by flow
    const filters = [];

    let currentNode = node;
    let currentGroupData = currentNode?.groupData;
    while (currentNode && currentNode?.groupData) {
        // I don't have a better way
        Object.keys(currentGroupData).forEach((key) => {
            const value = currentGroupData[key];
            if (value && value !== '') {
                totalGroupData.push([key, value]);
            } else {
                totalGroupData.push([key, null]);
            }
        });

        currentNode = currentNode?.parent;
        currentGroupData = currentNode?.groupData;
    }

    // @ts-expect-error [TS migration] Was not detected by flow
    totalGroupData.forEach((el) => {
        filters.push({
            field: el[0].replace('ag-Grid-AutoColumn-', ''),
            operation: 'equal',
            value: el[1],
        });
    });

    pivotKeys.forEach((pivotKey, i) => {
        if (pivotKey && i <= pivotColumns.length - 1) {
            filters.push({
                field: pivotColumns[i].getColId(),
                operation: 'equal',
                value: pivotKey,
            });
        }
        if (i > pivotColumns.length - 1) {
            console.error(
                'unexpected behavior the length of pivotKeys is higher than the length of pivotColumns',
            );
        }
    });
    // @ts-expect-error [TS migration] Was not detected by flow
    return filters;
};

export const getFilterFromClick = (
    clickedColumn: string,
    gridApi: GridApi,
    allFiltersFromNode: QueryFilterT[],
): QueryFilterT[] => {
    const displayedColumns = gridApi
        .getAllDisplayedColumns()
        .map((col) => col.getColId());
    const columnsToFilter = displayedColumns.filter(
        (col) =>
            displayedColumns.indexOf(col) <=
            displayedColumns.indexOf(clickedColumn),
    );
    const pivotColumns = gridApi.getPivotColumns().map((col) => col.getColId());
    const getColumnId = (col: string): string => `ag-Grid-AutoColumn-${col}`;
    return allFiltersFromNode.filter((filter) => {
        const camelizedField = camelize(filter.field);
        return (
            columnsToFilter.includes(getColumnId(camelizedField)) ||
            pivotColumns.includes(camelizedField)
        );
    });
};

const BUDGET_YEAR_REGEX = /^(20)(1|2|3)\d$/;

// this is what allows us to display columns conditionally on the pivoted year (ex in exercice - 3 we only display realized amount)
// see docs here: https://www.ag-grid.com/react-grid/pivoting/#manipulating-secondary-columns
// In order to use this helper, you need to define a function getColDefsForColumnsPivotedByYear where you define which column to hide per year
export const hideTCDColumnByYear = (
    colDef: ColDef,
    exercice: number,
    getColDefsForColumnsPivotedByYear: (
        exercice: number,
        phase?: BudgetPhases,
    ) => Record<number, Record<string, Pick<ColDef, 'hide'>>>,
    phase?: BudgetPhases,
) => {
    // NOTE: this will not work as expected if there are pivoted columns other than exercice which have value of the form 20XX
    const yearPivotKey = colDef.pivotKeys?.find((key) =>
        BUDGET_YEAR_REGEX.test(key),
    );

    // yearPivotKey and pivotValueColumn can be nullish
    if (!yearPivotKey || !colDef.pivotValueColumn) return;
    // @ts-expect-error [Private Column] We are ugly accessing to private property.
    const { colId } = colDef.pivotValueColumn;
    const colDefOverride = getColDefsForColumnsPivotedByYear(exercice, phase)[
        parseInt(yearPivotKey, 10)
    ];

    if (colDefOverride && colDefOverride[colId]) {
        Object.assign(colDef, colDefOverride[colId]);
    }
};

/* Wrapper around Aggrid's applyColumnState.
 * Do not use columnApi.applyColumnState directly, but this function instead.
 */
export const getApplyColumnState = (gridApi: GridApi | null) => {
    const applyColumnState = (currentColumnState: ColDef[]) => {
        if (gridApi === null) {
            return;
        }
        // TODO: this helper is probably not required anymore, AgGrid seems to handle this case
        gridApi.applyColumnState({
            // @ts-expect-error format colDefs to only one type
            state: currentColumnState.map((c) => {
                // width and flex cannot be both defined
                // if they are, the aggrid state cannot converge
                // see: https://www.ag-grid.com/react-data-grid/column-state/?#width-and-flex
                if (c.width !== null && c.width !== undefined) {
                    return { ...c, flex: null };
                }
                return c;
            }),
            applyOrder: true,
        });
    };
    return applyColumnState;
};
