import * as React from 'react';
import Badge from '@mui/material/Badge';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material';

const StyBadge = styled(Badge, {
    name: 'StyBadge',
})({
    verticalAlign: 'inherit',
    marginRight: '1.5rem', // keep some space for the badge
    '& .MuiBadge-badge': {
        background: 'none',
        right: '-0.50rem',
        top: '0.30rem',
    },
});

type Props = {
    tooltip: React.ReactNode;
    children: React.ReactNode;
};

/**
 * Display a badge with an info icon and a tooltip.
 */
const TextInfo = ({ tooltip, children }: Props): React.ReactElement => (
    <StyBadge
        color="secondary"
        badgeContent={
            <Tooltip title={tooltip} key="button-element">
                <InfoIcon color="primary" sx={{ fontSize: 16 }} />
            </Tooltip>
        }
    >
        {children}
    </StyBadge>
);

export default TextInfo;
