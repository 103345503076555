import React, { forwardRef, useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useDebounce } from 'hooks/helpers';

const TextFieldWithDebounce = forwardRef<HTMLInputElement, TextFieldProps>(
    ({ onChange, value, ...otherProps }, ref) => {
        const [debouncedValue, setDebouncedValue] = useState<unknown>(value);
        const [isEditing, setIsEditing] = useState(false);
        const debouncedOnChange = useDebounce(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                if (onChange) onChange(event);
                setIsEditing(false);
            },
            1000,
        );
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsEditing(true);
            setDebouncedValue(event.target.value);
            debouncedOnChange(event);
        };
        useEffect(() => {
            // if props.value has been updated by an API call while the user is editing, we don't want to override the user's value
            // note: the loose equality check is intentional, so "10" is considered equal to 10
            if (value != debouncedValue && !isEditing) setDebouncedValue(value);
            // we want to run this effect only when props.value change
        }, [value]);
        return (
            <TextField
                onChange={handleChange}
                value={debouncedValue}
                {...otherProps}
                ref={ref}
            />
        );
    },
);

TextFieldWithDebounce.displayName = 'TextFieldWithDebounce';

export default TextFieldWithDebounce;
