import React from 'react';
import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = CardProps;

const StyPaperCard = styled(Card, {
    name: 'StyPaperCard',
})({
    padding: '1rem',
    margin: '3rem 1.5rem 1rem',
});

const PaperCard = (props: Props) => {
    const { children, className } = props;
    return <StyPaperCard className={className}>{children}</StyPaperCard>;
};

export default PaperCard;
