import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

// mui components
import Grid from '@mui/material/Unstable_Grid2';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import HighlightIcon from '@mui/icons-material/Highlight';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import Tooltip from '@mui/material/Tooltip';
import { ResponsiveButton } from 'ui/ResponsiveButton/ResponsiveButton';

// see https://materialdesignicons.com
import InsertTableIcon from '@mdi/svg/svg/table-large-plus.svg';
import DeleteTableIcon from '@mdi/svg/svg/table-large-remove.svg';
import InsertRowAfterIcon from '@mdi/svg/svg/table-row-plus-after.svg';
import InsertRowBeforeIcon from '@mdi/svg/svg/table-row-plus-before.svg';
import DeleteRowIcon from '@mdi/svg/svg/table-row-remove.svg';
import InsertColumnAfterIcon from '@mdi/svg/svg/table-column-plus-after.svg';
import InsertColumnBeforeIcon from '@mdi/svg/svg/table-column-plus-before.svg';
import DeleteColumnIcon from '@mdi/svg/svg/table-column-remove.svg';
import MergeCellsIcon from '@mdi/svg/svg/table-merge-cells.svg';
import SplitCellsIcon from '@mdi/svg/svg/table-split-cell.svg';
import IndentDecrease from '@mdi/svg/svg/format-indent-decrease.svg';
import IndentIncrease from '@mdi/svg/svg/format-indent-increase.svg';
import CheckboxMarkedOutline from '@mdi/svg/svg/checkbox-marked-outline.svg';

import { AlternateEmail } from '@mui/icons-material';
import { Editor } from '@tiptap/core';
import ColorPicker from './ColorPicker';
import ImagePicker from './ImagePicker';
import EditorIcon from './EditorIcon';
import FontSizePicker from './FontSizePicker';

const StyMenuSpace = styled(Grid, {
    name: 'StyMenuSpace',
})({
    minWidth: '2rem',
});

const StyStickyGrid = styled(Grid, {
    name: 'StyStickyGrid',
})({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'white',
});

// @ts-expect-error [TS migration] Was not detected by flow
const setLink = (editor) => {
    const url = window.prompt('URL');
    if (url) {
        editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run();
    }
};

type Props = {
    editor: Editor | null;
    onSave: (html: string) => void;
    mentionEnabled: boolean;
    trackMentionCharClicked?: () => void;
};

export default function MenuBar({
    editor,
    onSave,
    mentionEnabled,
    trackMentionCharClicked,
}: Props): React.ReactElement | null {
    const { t } = useTranslation();
    if (!editor) {
        return null;
    }
    const saveCallback = () => onSave(editor.getHTML());
    return (
        <StyStickyGrid container alignItems="center">
            <FontSizePicker editor={editor} />

            <EditorIcon
                isActive={editor.isActive('bold')}
                onClick={() => editor.chain().focus().toggleBold().run()}
            >
                <FormatBoldIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() => editor.chain().focus().toggleItalic().run()}
                isActive={editor.isActive('italic')}
            >
                <FormatItalicIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                isActive={editor.isActive('underline')}
            >
                <FormatUnderlinedIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() => editor.chain().focus().toggleHighlight().run()}
                isActive={editor.isActive('highlight')}
            >
                <HighlightIcon />
            </EditorIcon>

            <ColorPicker editor={editor} />

            <EditorIcon
                onClick={() => setLink(editor)}
                isActive={editor.isActive('link')}
            >
                <LinkIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() => editor.chain().focus().unsetLink().run()}
                isActive={editor.isActive('link')}
            >
                <LinkOffIcon />
            </EditorIcon>

            {mentionEnabled && (
                <Tooltip
                    enterDelay={500}
                    title={t('dashboardHeader.mentionTooltip')}
                >
                    <EditorIcon
                        onClick={() => {
                            editor.chain().focus().insertContent('@').run();
                            trackMentionCharClicked?.();
                        }}
                    >
                        <AlternateEmail />
                    </EditorIcon>
                </Tooltip>
            )}

            <StyMenuSpace />

            <EditorIcon
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                isActive={editor.isActive('taskList')}
            >
                <CheckboxMarkedOutline />
            </EditorIcon>

            <EditorIcon
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                isActive={editor.isActive('bulletList')}
            >
                <FormatListBulletedIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                isActive={editor.isActive('orderedList')}
            >
                <FormatListNumberedIcon />
            </EditorIcon>

            {editor.isActive('listItem') && (
                <>
                    <StyMenuSpace />

                    <EditorIcon
                        onClick={() => editor.commands.liftListItem('listItem')}
                    >
                        <IndentDecrease />
                    </EditorIcon>

                    <EditorIcon
                        onClick={() => editor.commands.sinkListItem('listItem')}
                    >
                        <IndentIncrease />
                    </EditorIcon>
                </>
            )}

            <StyMenuSpace />

            <EditorIcon
                onClick={() =>
                    editor.chain().focus().setTextAlign('left').run()
                }
                isActive={editor.isActive({ textAlign: 'left' })}
            >
                <FormatAlignLeftIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() =>
                    editor.chain().focus().setTextAlign('center').run()
                }
                isActive={editor.isActive({ textAlign: 'center' })}
            >
                <FormatAlignCenterIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() =>
                    editor.chain().focus().setTextAlign('right').run()
                }
                isActive={editor.isActive({ textAlign: 'right' })}
            >
                <FormatAlignRightIcon />
            </EditorIcon>

            <EditorIcon
                onClick={() =>
                    editor.chain().focus().setTextAlign('justify').run()
                }
                isActive={editor.isActive({ textAlign: 'justify' })}
            >
                <FormatAlignJustifyIcon />
            </EditorIcon>

            <StyMenuSpace />

            {/* @ts-expect-error [TS migration] (previously $FlowFixMe) flow exact type migration: type issue */}
            <ImagePicker isAddAction editor={editor} />

            <StyMenuSpace />

            {!editor.isActive('table') ? (
                <EditorIcon
                    isAddAction
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .insertTable({
                                rows: 3,
                                cols: 3,
                                withHeaderRow: true,
                            })
                            .run()
                    }
                >
                    <InsertTableIcon />
                </EditorIcon>
            ) : (
                <>
                    <EditorIcon
                        isDeleteAction
                        onClick={() =>
                            editor.chain().focus().deleteTable().run()
                        }
                    >
                        <DeleteTableIcon />
                    </EditorIcon>

                    <EditorIcon
                        isAddAction
                        onClick={() =>
                            editor.chain().focus().addColumnBefore().run()
                        }
                    >
                        <InsertColumnBeforeIcon />
                    </EditorIcon>

                    <EditorIcon
                        isAddAction
                        onClick={() =>
                            editor.chain().focus().addColumnAfter().run()
                        }
                    >
                        <InsertColumnAfterIcon />
                    </EditorIcon>

                    <EditorIcon
                        isDeleteAction
                        onClick={() =>
                            editor.chain().focus().deleteColumn().run()
                        }
                    >
                        <DeleteColumnIcon />
                    </EditorIcon>

                    <EditorIcon
                        isAddAction
                        onClick={() =>
                            editor.chain().focus().addRowBefore().run()
                        }
                    >
                        <InsertRowBeforeIcon />
                    </EditorIcon>

                    <EditorIcon
                        isAddAction
                        onClick={() =>
                            editor.chain().focus().addRowAfter().run()
                        }
                    >
                        <InsertRowAfterIcon />
                    </EditorIcon>

                    <EditorIcon
                        isDeleteAction
                        onClick={() => editor.chain().focus().deleteRow().run()}
                    >
                        <DeleteRowIcon />
                    </EditorIcon>

                    <EditorIcon
                        onClick={() => editor.chain().focus().splitCell().run()}
                    >
                        <SplitCellsIcon />
                    </EditorIcon>
                    <Tooltip enterDelay={500} title={t('textEditor.mergeCell')}>
                        <EditorIcon
                            onClick={() =>
                                editor.chain().focus().mergeCells().run()
                            }
                        >
                            <MergeCellsIcon />
                        </EditorIcon>
                    </Tooltip>
                </>
            )}
            <StyMenuSpace />

            <EditorIcon onClick={() => editor.chain().focus().undo().run()}>
                <UndoIcon />
            </EditorIcon>

            <EditorIcon onClick={() => editor.chain().focus().redo().run()}>
                <RedoIcon />
            </EditorIcon>

            <StyMenuSpace sx={{ flexGrow: 1 }} />

            <ResponsiveButton
                icon={<CheckIcon color="primary" />}
                label={t('common.save')}
                extra={{
                    size: 'small',
                }}
                onClick={saveCallback}
            />
        </StyStickyGrid>
    );
}
