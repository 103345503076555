export type AutocompletAble<T> = {
    disabled?: boolean;
    label: string;
    value: T;
    isAll?: boolean;
};

export type ModalFormItem<T> = {
    children: React.ReactNode;
    value: AutocompletAble<T>[] | AutocompletAble<T> | null;
    setValue: DispatchState<AutocompletAble<T>[] | AutocompletAble<T> | null>;
};
/**
 * Returns a dropdown value item (with parameters disabled, label and isAll) formatted to work with our Autocomplete component.
 */
export function formatValueForAutocomplete<T>(
    value: T,
    disabled: boolean,
    label: string,
    isAll: boolean = false,
): AutocompletAble<T> {
    return {
        disabled,
        label,
        value,
        isAll,
    };
}
