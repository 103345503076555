import { styled } from '@mui/material/styles';

const StyPageContainer = styled('div', {
    name: 'StyPageContainer',
})(({ theme }) => ({
    flex: 1,
    overflowX: 'hidden',
    '@media print': {
        marginLeft: 0,
    },
    backgroundColor: theme.palette.lightGrey,
}));

export default StyPageContainer;
