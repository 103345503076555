import React, { useEffect, useState } from 'react';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { TreeItem, TreeView } from '@mui/x-tree-view';

type Props = {
    tree: TreeT;
    rowRenderer?: (node: TreeT) => JSX.Element;
    expandedIds?: string[];
    defaultExpandedIds?: string[];
};

const StyTreeItem = styled(TreeItem, {
    name: 'StyTreeItem',
})(({ theme }) => ({
    '.MuiTreeItem-content': {
        borderRadius: theme.spacing(1),
        MozUserSelect: 'none',
        KhtmlUserSelect: 'none',
        WebkitUserSelect: 'none',
        MsUserSelect: 'none',
        userSelect: 'none',
    },
    '.MuiTreeItem-content.Mui-focused': {
        backgroundColor: 'inherit',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));

export const Tree = ({
    tree,
    rowRenderer,
    expandedIds,
    defaultExpandedIds,
}: Props): JSX.Element => {
    const [expanded, setExpanded] = useState<string[]>([]);

    useEffect(() => {
        if (defaultExpandedIds) setExpanded(defaultExpandedIds);
    }, []);

    useEffect(() => {
        if (expandedIds) setExpanded(expandedIds);
    }, [expandedIds]);

    const renderNode = (node: TreeT) => {
        const label = rowRenderer ? rowRenderer(node) : node.label;
        return (
            <StyTreeItem key={node.id} nodeId={String(node.id)} label={label}>
                {node.children.map((n) => renderNode(n))}
            </StyTreeItem>
        );
    };

    return (
        <TreeView
            sx={{ flex: 1 }}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            disableSelection
            expanded={expanded}
            onNodeToggle={(_: unknown, nodeIds: string[]) =>
                setExpanded(nodeIds)
            }
        >
            {tree.children.map((node) => renderNode(node))}
        </TreeView>
    );
};
