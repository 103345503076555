import { MergePreserveOptional } from 'helpers/types';

type TreeWithIsDiplayed = MergePreserveOptional<
    TreeT,
    {
        isDisplayed: boolean;
        children: TreeWithIsDiplayed[];
    }
>;
type FlatTree = { label: string; value: string; id: number }[];

export const getAllNodeIds = (tree: TreeT): number[] => {
    const nodeIds: number[] = [tree.id];

    tree.children.forEach((child) => {
        const childrenNodeIds = getAllNodeIds(child);
        nodeIds.push(...childrenNodeIds);
    });

    return nodeIds;
};

/**
 * Returns a flattened version of a tree, containing only the `label`, `value` and `id` properties of each node.
 * @param tree - The root node of the tree.
 * @returns An array of objects representing each node in the tree.
 */
export const getFlatTreeWithId = (tree: TreeT): FlatTree => {
    const flatTree: FlatTree = [];

    function traverse(node: TreeT) {
        const { value, label, id } = node;
        flatTree.push({ label, value, id });
        node.children.forEach((child: TreeT) => traverse(child));
    }
    traverse(tree);
    return flatTree;
};

/**
 * This function will duplicate the tree and will add a field isDisplayed to every node.
 * An isDisplayed node field is defined depending by two parameters :
 *      1 - does the node mustBeDisplayed (depending on the function passed as a parameter)
 *      2 - do some of the children node isDisplayed
 * If one of this condition is true => the node isDisplayed = true
 */
export const enrichTreeWithIsDisplayedField = (
    tree: TreeT,
    mustNodeBeDisplayed: (nodeLabel: string) => boolean,
): TreeWithIsDiplayed => {
    const newChildren: TreeWithIsDiplayed[] = [];

    let isDisplayed = false;

    tree.children.forEach((child) => {
        const { children } = enrichTreeWithIsDisplayedField(
            child,
            mustNodeBeDisplayed,
        );
        const isChildDisplayed =
            mustNodeBeDisplayed(child.label) ||
            children.some((node) => node.isDisplayed);

        newChildren.push({
            ...child,
            isDisplayed: isChildDisplayed,
            children,
        });
        if (isChildDisplayed) isDisplayed = true;
    });

    return { ...tree, isDisplayed, children: newChildren };
};

export const filterTreeWithIsDisplayed = (
    tree: TreeWithIsDiplayed,
): TreeWithIsDiplayed => {
    const newChildren: TreeWithIsDiplayed[] = [];

    tree.children.forEach((child) => {
        if (child.isDisplayed) {
            const { children } = filterTreeWithIsDisplayed(child);

            newChildren.push({
                ...child,
                children,
            });
        }
    });

    return { ...tree, children: newChildren };
};

export const getDisplayedTree = (
    tree: TreeT,
    mustNodeBeDisplayed: (nodeLabel: string) => boolean,
): TreeWithIsDiplayed => {
    const treeWithIsDisplayedField = enrichTreeWithIsDisplayedField(
        tree,
        mustNodeBeDisplayed,
    );
    return filterTreeWithIsDisplayed(treeWithIsDisplayedField);
};

export const removeAccents = (str: string) =>
    str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const keepOnlyAlphaAndDigitCharacters = (str: string) =>
    // eslint-disable-next-line no-useless-escape
    str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');

const normalizeString = (str: string) =>
    keepOnlyAlphaAndDigitCharacters(removeAccents(str));

export const getCaseInsensitiveRegex = (pattern: string) =>
    new RegExp(pattern, 'i');

export const getNormalizedAndCaseInsensitiveRegex = (
    text: string | undefined,
): RegExp => {
    const normalizedSearchedText = normalizeString(text || '');
    const regexPattern = getCaseInsensitiveRegex(normalizedSearchedText);
    return regexPattern;
};

export function matchIgnoringCaseAndAccents(
    label: string | undefined,
    normalizedAndCaseInsensitiveRegex: RegExp,
) {
    const normalizedLabel = normalizeString(label || '');
    return normalizedAndCaseInsensitiveRegex.test(normalizedLabel);
}

export const removeElementsFromSelection = (
    selection: number[],
    elementsToRemove: number[],
) => selection.filter((node) => !elementsToRemove.includes(node));

export const addElementsToSelection = (
    selection: number[],
    elementsToAdd: number[],
): number[] => Array.from(new Set([...selection, ...elementsToAdd]));

export const getNodeAndItsDescendantsIds = (node: TreeT): number[] => {
    const childrenNodeAndTheirDescendansIds = node.children
        .map((child) => getNodeAndItsDescendantsIds(child))
        .flat();
    return [node.id, ...childrenNodeAndTheirDescendansIds];
};

const getAllSelectableNodeAndTheirDescendantsIds = (
    tree: TreeT,
    mustNodeBeDisplayed: (nodeLabel: string) => boolean,
): number[] => {
    if (mustNodeBeDisplayed(tree.label)) return getAllNodeIds(tree);
    const ids = tree.children.map((child) =>
        getAllSelectableNodeAndTheirDescendantsIds(child, mustNodeBeDisplayed),
    );
    return ids.flat();
};

export const getAllSelectableNodeAndTheirDescendantsIdsWithouRoot = (
    tree: TreeT,
    mustNodeBeDisplayed: (nodeLabel: string) => boolean,
): number[] => {
    const allSelectableNodeAndTheirDescendantsIds =
        getAllSelectableNodeAndTheirDescendantsIds(tree, mustNodeBeDisplayed);
    return allSelectableNodeAndTheirDescendantsIds.filter(
        (id) => id !== tree.id,
    );
};
