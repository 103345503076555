import React from 'react';
import { ListItem } from '@mui/material';
import AutosizerFlex from 'components/AutosizerFlex';
// @ts-expect-error [TS migration] Was not detected by flow
import List from 'react-virtualized/dist/commonjs/List';

type ListRowRendererProps = { index: number; style: React.CSSProperties };
type ListRowRenderer = (props: ListRowRendererProps) => React.ReactElement;

type Value = Omit<TreeT, 'children'>;

type Props = {
    values: Value[];
    rowRenderer: (node: Value) => JSX.Element;
};

export const SelectableList = ({ values, rowRenderer }: Props) => {
    const listRowRenderer: ListRowRenderer = ({ index, style }) => {
        const value = values[index];

        return (
            <ListItem key={value.id} style={style}>
                {rowRenderer(value)}
            </ListItem>
        );
    };

    return (
        <AutosizerFlex>
            {({ height, width }) => (
                <List
                    height={height - 4} // - 4 is useful to fix a bug of indefinite resizing
                    width={width - 4}
                    rowCount={values.length}
                    rowHeight={42}
                    rowRenderer={listRowRenderer}
                />
            )}
        </AutosizerFlex>
    );
};
