import * as React from 'react';
import { Box, styled } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

const StyBox = styled(Box, {
    name: 'StyBox',
})({
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    height: 'fit-content',
    padding: '0.5rem 0.5rem',
    minWidth: '12rem',
    alignItems: 'center',
    gap: '1rem',
    fontSize: '0.7rem',
    borderRadius: '4px',
    justifyContent: 'center',
});

type Props = {
    Icon?: React.ElementType<SvgIconProps>;
    children?: React.ReactNode;
    style?: React.CSSProperties;
};

/**
 * Renders an InfoBox component with optional number, Icon, and children.
 *
 * Icon - The Icon component to display.
 * children - The children components to render.
 * style - The style object for the InfoBox container.
 */
const InfoBox = ({ Icon, children, style }: Props): React.ReactElement<any> => (
    <StyBox sx={{ ...style }}>
        {Icon && <Icon sx={{ fontSize: '1.2rem' }} />}
        {children}
    </StyBox>
);

export default InfoBox;
