import React from 'react';
import { CellRendererParams } from 'routes/BudgetModule/config/types';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import RoundedIcon from '@mui/icons-material/CloseRounded';
import { Box } from '@mui/material';

const BooleanCellRenderer: React.FC<
    CellRendererParams<boolean | undefined>
> = ({ value }) => {
    if (value === undefined) return <></>;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '0 auto',
            }}
        >
            {value === true ? (
                <DoneRoundedIcon sx={{ color: 'green' }} />
            ) : (
                <RoundedIcon sx={{ color: 'grey' }} />
            )}
        </Box>
    );
};

export default BooleanCellRenderer;
