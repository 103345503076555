import * as React from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import { styled } from '@mui/material/styles';
import Placeholder from '@tiptap/extension-placeholder';

import StarterKit from '@tiptap/starter-kit';

import { useTranslation } from 'react-i18next';
import { TRACKABLE_EVENTS, trackEventBudget } from 'helpers/trackerHelpers';
import { Suggestion } from './Suggestion';
import { useGetSuggestedUsers } from './hooks';
import { MentionStyle } from './styles';
import Mention from './mantyMention';
import { defaultFilterForBudgetUsersSuggestions } from './helpers';

const StyEditorContent = styled(EditorContent, {
    name: 'StyEditorContent',
    shouldForwardProp: (propName) => propName !== 'isNewComment',
})<{ isNewComment: boolean }>(({ theme, isNewComment }) => ({
    width: '100%',
    minHeight: isNewComment ? '89px' : '56px',
    '& .ProseMirror': {
        padding: '6px 16px',
        lineHeight: '1.75em',
    },
    ...MentionStyle(theme),
}));

type Props = {
    isEditable: boolean;
    content: string;
    setContent: React.Dispatch<React.SetStateAction<string>>;
    shouldReset?: boolean;
    setShouldReset: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CommentEditor({
    isEditable,
    content,
    setContent,
    shouldReset,
    setShouldReset,
}: Props): React.ReactElement {
    const { data: users, isLoading } = useGetSuggestedUsers(
        defaultFilterForBudgetUsersSuggestions,
    );
    const { t } = useTranslation();
    const initValue = React.useRef(content);

    const trackerFn = () => {
        trackEventBudget(
            TRACKABLE_EVENTS.budget.general.addInscriptionCommentMention,
        );
    };

    const editor = useEditor(
        {
            editable: isEditable,
            extensions: [
                StarterKit,
                Mention.configure({
                    boundingBox: { left: 104, right: 58 },
                    margin: 16,
                    suggestion: Suggestion(users, trackerFn),
                }),
                Placeholder.configure({
                    placeholder: () => t('budgetHistory.commentPlaceholder'),
                }),
            ],
            content,
        },
        [isLoading],
    );

    React.useEffect(() => {
        if (editor) {
            setContent(editor.getHTML());
            editor.setEditable(isEditable);
            if (isEditable) {
                editor.commands.focus();
            }
        }
    }, [editor, editor?.getHTML(), isEditable]);

    React.useEffect(() => {
        if (shouldReset) {
            if (editor) {
                editor.commands.setContent(initValue.current);
            }
            setShouldReset(false);
        }
    }, [editor, shouldReset]);

    return (
        <>
            {!isLoading && (
                <StyEditorContent
                    isNewComment={initValue.current === ''}
                    editor={editor}
                />
            )}
        </>
    );
}
