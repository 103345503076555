import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

const PermissionErrorText = () => {
    const { t } = useTranslation();

    return (
        <>
            <Typography sx={{ fontSize: '2rem', mt: '1rem' }}>
                {t('chart.permissionsError')}
            </Typography>
            <Typography sx={{ fontSize: '1rem' }}>
                {t('chart.rightsAccess')}{' '}
                <Link href="/profile" underline="always">
                    {t('chart.rightsPage')}
                </Link>
            </Typography>
        </>
    );
};

export default PermissionErrorText;
