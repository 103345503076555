import React from 'react';
import MuiToggleButtonGroup, {
    ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material';

const StyToggleButtonGroup = styled(MuiToggleButtonGroup, {
    name: 'StyToggleButtonGroup',
})({
    '& .MuiToggleButtonGroup-grouped': {
        border: 'none',
        '&:not(:last-child)': {
            marginRight: '1rem',
        },
    },
});

const ToggleButtonGroup = (
    props: ToggleButtonGroupProps,
): React.ReactElement => <StyToggleButtonGroup {...props} />;

export default ToggleButtonGroup;
