import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addElementsToSelection, removeElementsFromSelection } from './helpers';

type Props = {
    selectedNodeIds: number[];
    setSelectedNodeIds: (n: number[]) => void;
    allSelectableNodeIds: number[];
};

const SelectAllCheckbox = ({
    selectedNodeIds,
    setSelectedNodeIds,
    allSelectableNodeIds,
}: Props) => {
    const { t } = useTranslation();

    const areAllNodesSelected = useMemo(() => {
        const selectedNodeIdsSet = new Set(selectedNodeIds);
        return allSelectableNodeIds.every((id) => selectedNodeIdsSet.has(id));
    }, [allSelectableNodeIds, selectedNodeIds]);

    const isNoNodeSelected = useMemo(
        () => allSelectableNodeIds.every((id) => !selectedNodeIds.includes(id)),
        [allSelectableNodeIds, selectedNodeIds],
    );

    const isIndeterminate = !isNoNodeSelected && !areAllNodesSelected;

    const handleSelectAll = () => {
        const newSelectedIds = areAllNodesSelected
            ? removeElementsFromSelection(selectedNodeIds, allSelectableNodeIds)
            : addElementsToSelection(selectedNodeIds, allSelectableNodeIds);

        setSelectedNodeIds(newSelectedIds);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    indeterminate={isIndeterminate}
                    checked={areAllNodesSelected}
                    onClick={() => handleSelectAll()}
                />
            }
            label={t('common.selectAll')}
        />
    );
};

export default SelectAllCheckbox;
