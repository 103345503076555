import React from 'react';
import { Paper, styled } from '@mui/material';
import Flex from 'ui/Flex/Flex';

type Props = {
    children: React.ReactNode;
    className?: string;
};

const StyLoginPageFlexContainer = styled(Flex, {
    name: 'StyLoginPageFlexContainer',
})({
    flex: 1,
});

const StyCenteredCard = styled(Paper, {
    name: 'StyCenteredCard',
})({
    maxWidth: '600px',
    padding: '1.5rem',
    zIndex: 2,
});

const CenteredCard = ({ children, className }: Props) => (
    <StyLoginPageFlexContainer
        direction="column"
        justify="center"
        align="center"
        className={className}
    >
        <StyCenteredCard>{children}</StyCenteredCard>
    </StyLoginPageFlexContainer>
);

export default CenteredCard;
