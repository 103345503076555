export type ComparatorT = (a: string, b: string) => number;

export const percentageComparator = (a: string, b: string): number => {
    // percentages are often replaced to "-" when null
    if (a && (!b || b === '-')) {
        return 1;
    }
    if ((!a || a === '-') && b) {
        return -1;
    }

    if (!a || a === '-' || !b || b === '-') {
        return 0;
    }
    const numberA = parseFloat(a);
    const numberB = parseFloat(b);
    if (numberA < numberB) {
        return -1;
    }
    if (numberA > numberB) {
        return 1;
    }
    return 0;
};
