import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material';
import { CustomFloatingFilterProps } from 'ag-grid-react';

const startAdornment = (
    <InputAdornment position="start">
        <SearchIcon fontSize="small" sx={{ color: 'muiBlack' }} />
    </InputAdornment>
);

const StyTextField = styled(TextField, {
    name: 'StyTextField',
})({
    '& .MuiInputBase-input': {
        fontSize: '12px',
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '6px',
    },
    '& .MuiInputAdornment-positionStart': {
        marginRight: '4px',
    },
});

const NumberFloatingFilter = ({
    model,
    onModelChange,
}: CustomFloatingFilterProps) => {
    const { t } = useTranslation();
    const onChange = ({
        target: { value: newValue },
    }: React.ChangeEvent<HTMLInputElement>) => {
        onModelChange(
            newValue === ''
                ? null
                : {
                      ...(model || { type: 'greaterThanOrEqual' }),
                      filter: newValue,
                  },
        );
    };

    return (
        <StyTextField
            value={model?.filter || ''}
            size="small"
            placeholder={t('budgetProposal.search')}
            InputProps={{
                startAdornment,
                type: 'number',
                disableInjectingGlobalStyles: true,
            }}
            onChange={onChange}
            variant="outlined"
        />
    );
};

export default NumberFloatingFilter;
