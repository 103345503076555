import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { SHADOW } from 'styles/theme';

const StyBox = styled(Box, {
    name: 'StyBox',
    shouldForwardProp: (prop) => prop !== 'shadow',
})<{ shadow?: boolean }>(({ shadow }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1.5rem',
    height: '78px',
    minHeight: '78px',
    boxShadow: shadow ? SHADOW : 'none',
    backgroundColor: 'white',
    zIndex: 1,
}));

type Props = {
    title: string | React.ReactNode;
    actions?: React.ReactNode;
    shadow?: boolean;
};

const HeaderLayout = ({
    title,
    actions = undefined,
    shadow = true,
}: Props): React.ReactElement => (
    <StyBox shadow={shadow}>
        <Typography variant="h1" color="primary">
            {title}
        </Typography>
        {actions}
    </StyBox>
);

export default React.memo(HeaderLayout);
