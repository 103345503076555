import * as React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, styled } from '@mui/material';

const StyAlert = styled(Alert, {
    name: 'StyAlert',
})(({ theme }) => ({
    padding: '1rem 1.5rem',
    borderRadius: '0.25rem',
    '&.MuiAlert-standardInfo': {
        backgroundColor: theme.palette.primary.light,
    },
}));

const StyColoredText = styled(Typography, {
    name: 'StyColoredText',
})(({ theme }) => ({
    color: theme.palette.darkGrey,
}));

export default ({
    severity,
    icon,
    action,
    children,
    onClose,
    className,
}: AlertProps) => (
    <StyAlert
        className={className}
        severity={severity}
        icon={icon}
        action={
            <Box sx={{ display: 'flex' }}>
                {action}
                {onClose ? (
                    <IconButton onClick={onClose} size="large">
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </Box>
        }
        onClose={onClose}
    >
        <StyColoredText variant="h6">{children}</StyColoredText>
    </StyAlert>
);
