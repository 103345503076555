import { ADMIN, BUDGET_USER, DECISION_USER, SUPPORT } from 'business/constants';
import { EnrichedUserT } from 'business/definitions';
import { SuggestedUsersFilter } from './hooks';

export const parseMentionsFrom = (content?: string): number[] => {
    if (!content) {
        return [];
    }
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;
    const mentionElements = tempElement.querySelectorAll(
        'span[data-type="mention"]',
    );

    const ids: number[] = [];
    for (let i = 0; i < mentionElements.length; i += 1) {
        const id = mentionElements[i].getAttribute('data-id');
        if (id) {
            ids.push(parseInt(id, 10));
        }
    }
    return ids;
};

export const defaultFilterForBudgetUsersSuggestions: SuggestedUsersFilter = (
    users: EnrichedUserT[],
): EnrichedUserT[] =>
    users.filter((user) => {
        const isNotSupport = !user.roles.includes(SUPPORT);
        const isBudgetUser = user.roles.includes(BUDGET_USER);
        return isNotSupport && isBudgetUser;
    });

export const defaultFilterForDecisionUsersSuggestions: SuggestedUsersFilter = (
    users: EnrichedUserT[],
): EnrichedUserT[] =>
    users.filter((user) => {
        const isNotSupport = !user.roles.includes(SUPPORT);
        const isDecisionUser =
            user.roles.includes(DECISION_USER) || user.roles.includes(ADMIN);
        return isNotSupport && isDecisionUser;
    });
