import TaskItem from '@tiptap/extension-task-item';

/**
 * We extend TaskItem Extension to add a align-items attribute with a fixed value of center.
 */
export const EnrichedTaskItemStyleExtension = TaskItem.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            alignItems: {
                default: 'center',
                parseHTML: (element) => element.style.alignItems,
                renderHTML: (attributes) => ({
                    style: `align-items: ${attributes.alignItems}`,
                }),
            },
        };
    },
});
