import * as React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Box, styled } from '@mui/material';

const StyLoader = styled(CircularProgress, {
    name: 'StyLoader',
})({
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    margin: 'auto',
    top: 0,
    bottom: 0,
});

type Props = {
    isLoading: boolean;
    children: React.ReactNode;
    fullWidth?: boolean;
    color?: CircularProgressProps['color'];
};

const WithLoader: React.FC<Props> = ({
    isLoading,
    children,
    fullWidth,
    ...otherProps
}) => (
    <Box
        sx={{ position: 'relative', width: fullWidth ? '100%' : 'fit-content' }}
    >
        {isLoading && <StyLoader {...otherProps} />}
        {children}
    </Box>
);

export default WithLoader;
