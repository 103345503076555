/* eslint-disable react/require-default-props */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs, { TabsTypeMap } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useGetActiveTab, useGetVisitedTabs } from 'ui/TabulatedPage/hooks';

const StyTabs = styled(Tabs, {
    name: 'StyTabs',
})(({ theme }) => ({
    height: '39px',
    minHeight: '39px',
    '& .Mui-selected': {
        backgroundColor: theme.palette.primary.light,
    },
}));

const StyTab = styled(Tab, {
    name: 'StyTab',
})<React.ComponentProps<typeof Link> & React.ComponentProps<typeof Tab>>(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: '600',
        minHeight: '39px',
        [theme.breakpoints.up('md')]: {
            minWidth: '160px',
        },
        fontSize: '14px',
        '&:focus': {
            color: theme.palette.primary.main,
        },
        '& span': {
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            '& svg ': {
                marginRight: '0.5rem',
                marginBottom: '0 !important',
            },
        },
        '& .MuiTab-labelIcon': {
            minHeight: '0',
        },
    }),
);

// Some URL structure taxonomy reminders:
// Considering this example: https://demo.manty.eu:80/example/1?with_email=1#main-content
// its parts are:
// href: https://demo.manty.eu:80/example/1?with_email=1#main-content
// protocol: https
// host: demo.manty.eu:80
// hostname: demo.manty.eu
// domain: manty.eu
// subdomain: demo
// top-level domain: .eu
// port: 80
// path: example/1?with_email=1
// pathname: example/1
// search or query: ?with_email=1
// anchor or hash: #main-content

export type TabulatedPageTab = {
    label: string;
    icon?: React.ReactElement;
    children: React.ReactNode;
    pathname: string;
    search?: string;
    disabled: boolean;
    onClick?: () => void;
};

export type TabulatedPageTabWithValue = TabulatedPageTab & {
    value: string; // this is the computed pathname + search, depending on various factors
};

type Props = {
    tabsList: TabulatedPageTab[];
    keepSearch?: boolean;
    className?: string;
    variant?: TabsTypeMap['props']['variant'];
    tabsStyles?: React.CSSProperties;
};

export default function TabulatedPage({
    tabsList,
    keepSearch = false,
    className = '',
    variant,
    tabsStyles,
}: Props): React.ReactElement {
    const { search: historySearch = '' } = useLocation();

    // we add the value property to each tab, which depends on the necessity of keeping the search part of the URL
    const tabsListWithTabValue: TabulatedPageTabWithValue[] = tabsList.map(
        (tab) => {
            const { search: tabSearch } = tab;
            const search = keepSearch ? historySearch : tabSearch;
            return {
                ...tab,
                value: search ? tab.pathname + search : tab.pathname,
            };
        },
    );

    const activeTabValue = useGetActiveTab(tabsListWithTabValue, keepSearch);

    const visitedTabs = useGetVisitedTabs(tabsListWithTabValue, activeTabValue);

    const tabsComponents = tabsListWithTabValue.map((tabWithValue) => {
        const { label, icon, disabled, onClick, value } = tabWithValue;
        return (
            <StyTab
                key={value}
                value={value}
                label={label}
                icon={icon}
                to={value}
                disableRipple
                disabled={disabled}
                LinkComponent={Link}
                onClick={onClick}
            />
        );
    });
    const tabsContentComponent = tabsListWithTabValue.map((tabWithValue) => {
        const { children, value } = tabWithValue;
        // NOTE optimization: if the tab has already been visited, let's keep it in the DOM in order to not unmount it
        const shouldMountChildren = visitedTabs[value];
        return (
            <div
                key={value}
                hidden={value !== activeTabValue}
                className="Mui-TabulatedPage-content-div"
            >
                {shouldMountChildren && children}
            </div>
        );
    });
    return (
        <Box className={className}>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={tabsStyles}
            >
                <StyTabs
                    value={activeTabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={variant}
                >
                    {tabsComponents}
                </StyTabs>
            </AppBar>
            {tabsContentComponent}
        </Box>
    );
}
