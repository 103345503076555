import { UserInfoT, UserT } from 'business/definitions';
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import {
    MenuItem,
    MenuList,
    Paper,
    Popper,
    PopperPlacementType,
} from '@mui/material';
import { getUserLabel } from './mantyMention';

interface MentionListProps extends SuggestionProps {
    items: (UserT & UserInfoT)[];
    command: ({ id }: { id: number }) => void;
    clientRect: (() => DOMRect) | null;
    trackMentionCreation?: () => void;
}

interface MentionListActions {
    onKeyDown: (props: SuggestionKeyDownProps) => void;
}

export default forwardRef<MentionListActions, MentionListProps>(
    (props, ref) => {
        const [selectedIndex, setSelectedIndex] = useState(0);
        const { clientRect, command, items, trackMentionCreation } = props;

        const referenceEl = useMemo(
            () => (clientRect ? { getBoundingClientRect: clientRect } : null),
            [clientRect],
        );

        const selectItem = (index: number) => {
            const item = items[index];

            if (item) {
                command({ id: item.id });
                trackMentionCreation?.();
            }
        };

        const upHandler = () => {
            setSelectedIndex((selectedIndex + items.length - 1) % items.length);
        };

        const downHandler = () => {
            setSelectedIndex((selectedIndex + 1) % items.length);
        };

        const enterHandler = () => {
            selectItem(selectedIndex);
        };

        useEffect(() => setSelectedIndex(0), [items]);

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }: { event: KeyboardEvent }) => {
                if (event.key === 'ArrowUp') {
                    upHandler();
                    return true;
                }

                if (event.key === 'ArrowDown') {
                    downHandler();
                    return true;
                }

                if (event.key === 'Enter') {
                    enterHandler();
                    return true;
                }

                return false;
            },
        }));
        if (
            referenceEl === null ||
            referenceEl.getBoundingClientRect() === null
        )
            return <></>;

        return (
            <Popper
                open={Boolean(referenceEl)}
                style={{ zIndex: 1600 }}
                anchorEl={referenceEl}
                placement="right-start"
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: (args: {
                                popper: DOMRect;
                                reference: DOMRect;
                                placement: PopperPlacementType;
                            }): [number, number] => [args.reference.height, 0],
                        },
                    },
                ]}
            >
                <Paper>
                    <MenuList>
                        {items
                            .slice(0, 5)
                            .map((item: UserT & UserInfoT, index) => (
                                <MenuItem
                                    key={item.email}
                                    selected={index === selectedIndex}
                                    onClick={() => selectItem(index)}
                                >
                                    {getUserLabel(item)}
                                </MenuItem>
                            ))}
                    </MenuList>
                </Paper>
            </Popper>
        );
    },
);
