import { isArray } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { AutocompletAble, ModalFormItem } from './types';

type WrapperProps<T> = {
    items: Array<ModalFormItem<T>>;
};

const isEmpty = <T,>(
    value: AutocompletAble<T>[] | AutocompletAble<T> | null,
) => {
    if (value === null) return true;
    if (isArray(value)) return value.length === 0;
    return false;
};

const ResetWrapper = <T,>({ items }: WrapperProps<T>): React.ReactElement => {
    const [showItems, setShowItems] = React.useState<boolean[]>(
        items.map((e, i) => {
            // The first item is always visible, others are visible if the previous one is not empty
            if (i === 0) return true;
            if (items[i - 1].value === null) return false;
            return true;
        }),
    );
    const previousItems = useRef(items);

    /* Reccursive useEffect/useRef combo, strictly equivalent to :
       function f(i) {
            if (item[i].value == null)
                =>  item[i +1].value = null
                    item[i+1].visible = false
            else
                =>  item[i + 1].value = null
                    item[i+1].visible = true
            if (i + 1 < len(items))
                =>  f(i+1)
        }
    */
    useEffect(() => {
        const changedIndex = previousItems.current.findIndex(
            (prevItem, i) => prevItem.value !== items[i].value,
        );
        if (changedIndex !== -1 && changedIndex + 1 < items.length) {
            // If the value of the current changed item is empty, we hide the next item
            // and reset its value (to avoid having a list of gestionnaire when we go back and select another resource type)
            const isChangedToNoValue = isEmpty(items[changedIndex].value);
            setShowItems((prevShowItems) =>
                prevShowItems.map((show, j) =>
                    j === changedIndex + 1 ? !isChangedToNoValue : show,
                ),
            );
            items[changedIndex + 1].setValue(null);
        }
        previousItems.current = items;
    }, [items]);

    return (
        <>
            {items.map(
                (item, index) =>
                    (index === 0 || showItems[index]) && item.children,
            )}
        </>
    );
};

export default ResetWrapper;
