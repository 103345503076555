import { ReactRenderer } from '@tiptap/react';
import Fuse from 'fuse.js';
import { UserInfoT, UserT } from 'business/definitions';
import Loading from 'components/Loading';
import {
    SuggestionKeyDownProps,
    SuggestionOptions,
    SuggestionProps,
} from '@tiptap/suggestion';
import MentionList from './MentionList';

// that filtering is not good
const filterUsers = (
    users: (UserT & UserInfoT)[],
    query: string,
): (UserT & UserInfoT)[] => {
    if (query === '') {
        return users.sort((userA, userB) => {
            const userAStr =
                (userA.firstname ?? '') +
                (userA.lastname ?? '') +
                (userA.email ?? '');
            const userBStr =
                (userB.firstname ?? '') +
                (userB.lastname ?? '') +
                (userB.email ?? '');
            return userAStr.localeCompare(userBStr);
        });
    }
    const fuse = new Fuse(users, {
        keys: ['firstname', 'lastname', 'email'],
        threshold: 0.4,
    });
    return fuse.search(query).map((o) => o.item);
};

export const Suggestion = (
    users: (UserT & UserInfoT)[] | undefined,
    trackMentionCreation?: () => void,
): {
    items: ({ query }: { query: string }) => (UserT & UserInfoT)[];
    render: () => ReturnType<NonNullable<SuggestionOptions['render']>>;
} => ({
    items: ({ query }) => filterUsers(users === undefined ? [] : users, query),
    render: () => {
        let component: ReactRenderer;

        return {
            onStart: (props: SuggestionProps) => {
                component =
                    users === undefined
                        ? new ReactRenderer(Loading, {
                              props,
                              editor: props.editor,
                          })
                        : new ReactRenderer(MentionList, {
                              props: { ...props, trackMentionCreation },
                              editor: props.editor,
                          });
            },

            onUpdate(props: SuggestionProps) {
                component.updateProps(props);
            },

            onKeyDown(props: SuggestionKeyDownProps) {
                if (props.event.key === 'Escape') {
                    component.destroy();
                    return true;
                }

                return (component.ref as any)?.onKeyDown(props);
            },

            onExit() {
                component.destroy();
            },
        };
    },
});
