import * as React from 'react';
import { styled } from '@mui/material/styles';
import GenericCard from './GenericCard';

type Props = {
    title: string;
    icon?: React.ReactNode;
    action?: React.ReactNode;
};

const StyUnclickableCard = styled(GenericCard, {
    name: 'StyUnclickableCard',
})({
    '& a': {
        cursor: 'initial',
    },
});

const UnclickableCard = ({
    title,
    icon,
    action,
}: Props): React.ReactElement => (
    <StyUnclickableCard title={title} icon={icon} action={action} />
);

export default UnclickableCard;
