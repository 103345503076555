import * as React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button, { ButtonProps, ButtonTypeMap } from '@mui/material/Button';
import SubmitModal from 'components/Submits/SubmitModal';
import { SxProps, Theme } from '@mui/material';
import Flex from 'ui/Flex/Flex';
import { styled } from '@mui/material/styles';
import { omit } from 'lodash';

type ResponsiveButtonProps = {
    onClick?: FlowAnyFunction;
    className?: string;
    sx?: SxProps<Theme>;
    label: React.ReactNode;
    color?: ButtonTypeMap['props']['color'];
    icon?: React.ReactNode;
    extra: ButtonProps;
};

const StyButtonWithIcon = styled(Button, {
    name: 'StyButtonWithIcon',
})(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'non',
    },
    display: 'flex',
    padding: '6px 16px',
    svg: {
        marginRight: '6px',
    },
}));

export function ResponsiveButton({
    onClick = () => {},
    extra,
    color = undefined,
    className = undefined,
    sx = undefined,
    icon = undefined,
    label,
}: ResponsiveButtonProps): React.ReactElement {
    const iconButtonCompatibleProps: IconButtonProps = omit(extra, 'endIcon');
    return (
        <Flex align="center">
            <StyButtonWithIcon
                color={color}
                onClick={onClick}
                className={className}
                sx={sx}
                {...extra}
            >
                {icon}
                {label}
            </StyButtonWithIcon>
            <IconButton
                onClick={onClick}
                color={color}
                className={className}
                sx={{ display: { xs: 'flex', sm: 'none' }, ...sx }}
                {...iconButtonCompatibleProps}
                size="large"
            >
                {icon}
            </IconButton>
        </Flex>
    );
}
type ResponsiveButtonWithConfirmationProps = {
    onSubmitClick: FlowAnyFunction;
    label: string;
    color?: ButtonTypeMap['props']['color'];
    icon: React.ReactNode;
    extra?: ButtonProps;
    dialogTitle: string;
    children: React.ReactNode;
};
export function ResponsiveButtonWithConfirmation({
    extra = {},
    color = undefined,
    icon,
    label,
    children,
    dialogTitle,
    onSubmitClick,
}: ResponsiveButtonWithConfirmationProps): React.ReactElement {
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <ResponsiveButton
                onClick={() => setOpen(true)}
                color={color}
                extra={extra}
                icon={icon}
                label={label}
            />
            <SubmitModal
                submit={onSubmitClick}
                dialogText={children}
                dialogTitle={dialogTitle}
                open={open}
                setOpen={setOpen}
            />
        </>
    );
}
