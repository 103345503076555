import React from 'react';

import { styled } from '@mui/material';
import { LargeTitle } from './LargeTitle';

type Props = {
    className?: string;
    title: string;
};

const StyLargeTitleWithMargin = styled(LargeTitle, {
    name: 'StyLargeTitleWithMargin',
})({
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
});

const LargeTitleWithMargin = (props: Props) => {
    const { className, title } = props;
    return <StyLargeTitleWithMargin className={className} title={title} />;
};

export default LargeTitleWithMargin;
