import { Switch, styled } from '@mui/material';

export const StySwitch = styled(Switch, { name: 'StySwitch' })(({ theme }) => ({
    width: '2.25rem',
    height: '1.25rem',
    padding: 0,
    marginRight: '0.5rem',
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: '1.125rem',
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: '0.125rem',
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: theme.palette.primary.main,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.light,
            },
            right: '0.563rem',
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: '1rem',
        height: '1rem',
        borderRadius: '0.563rem',
    },
    '& .MuiSwitch-track': {
        borderRadius: 18 / 2,
        opacity: 1,
        backgroundColor: theme.palette.lightGrey,
        boxSizing: 'border-box',
    },
}));
