import { FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import React from 'react';

type Props = {
    title: string;
    tooltipTitle: string;
    isChecked?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ToggleWithTooltip = ({
    title,
    tooltipTitle,
    isChecked = false,
    onChange,
}: Props): React.ReactElement => {
    return (
        <Tooltip title={tooltipTitle}>
            <FormControlLabel
                control={<Switch checked={isChecked} onChange={onChange} />}
                disableTypography
                label={<Typography>{title}</Typography>}
            />
        </Tooltip>
    );
};
export default ToggleWithTooltip;
