import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
    Box,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    ListItemIcon,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { IconMenuProps } from 'components/IconMenu';
import { capitalize } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddchartIcon from '@mui/icons-material/Addchart';
import AddBoxIcon from '@mui/icons-material/AddBox';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

// This function checks whether a point (x, y) is on the left or right side of a line formed by two points (px, py) and (qx, qy).
// If the result is negative, the point is on the right side of the line. If positive, it's on the left side.
// It helps us determine if a point is on the same side as a vertex of the triangle when compared to its edges.
const sign = (
    px: number,
    py: number,
    qx: number,
    qy: number,
    rx: number,
    ry: number,
) => (px - rx) * (qy - ry) - (qx - rx) * (py - ry);

// This function checks if a point (x, y) is inside a triangle formed by three points (x1, y1), (x2, y2), and (x3, y3).
const pointInTriangle = (
    currentMouseCoordinates: Array<number>,
    triangleCoordinates: Array<Array<number>>,
) => {
    const [[x1, y1], [x2, y2], [x3, y3]] = triangleCoordinates;
    const [x, y] = currentMouseCoordinates;

    const b1 = sign(x, y, x1, y1, x2, y2) <= 0;
    const b2 = sign(x, y, x2, y2, x3, y3) <= 0;
    const b3 = sign(x, y, x3, y3, x1, y1) <= 0;
    // If all signs are the same (either all negative or all positive), the point is inside the triangle.
    return b1 === b2 && b2 === b3;
};

type NestedMenuButtonT = {
    text?: string;
    isIconButton?: boolean;
    iconButton?: IconMenuProps;
};

export const CHART_ACTION_TYPE = {
    Alert: 'alert',
    Delete: 'delete',
    Duplicate: 'duplicate',
    Edit: 'edit',
    Rename: 'rename',
    Resize: 'resize',
    Update: 'update',
    Insert: 'insert',
    InsertIndicator: 'insertIndicator',
    CopyAnchor: 'copyAnchor',
} as const;

export type ChartActionType =
    (typeof CHART_ACTION_TYPE)[keyof typeof CHART_ACTION_TYPE];

export type NestedMenuOptionT = {
    menuLevel: number;
    action: ChartActionType;
    onClick: (action: ChartActionType) => void;
    separator?: boolean;
    nestedOptions?: Array<NestedMenuOptionT>;
};

export type SubMenuProps = {
    menuLevels: number;
    options: Array<NestedMenuOptionT>;
    onOptionClick: (option: NestedMenuOptionT) => void;
    button?: NestedMenuButtonT;
    triggerOpenEvent?:
        | React.MouseEvent<HTMLElement>
        | React.KeyboardEvent<HTMLElement>;
    anchorPosition?: { top: number; left: number };
    onClickAway?: () => void;
};

const NestedMenu = ({
    options,
    menuLevels,
    onOptionClick,
    button,
    triggerOpenEvent,
    anchorPosition,
    onClickAway,
}: SubMenuProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [anchors, setAnchors] = React.useState<{
        elements: Array<null | HTMLElement>;
        options: Array<null | typeof options>;
    }>({
        elements: new Array(menuLevels).fill(null),
        options: new Array(menuLevels).fill(null),
    });

    const mouseEntered = React.useRef<Record<string, boolean>>({});
    const mouseLeftCordinates = React.useRef<Array<number>>([]);
    const buttonRef = React.useRef(null);
    const mouseIdleTimer = React.useRef<NodeJS.Timeout | null>(null);

    const icons = {
        alert: <NotificationsIcon />,
        delete: <DeleteIcon />,
        duplicate: <AddIcon />,
        edit: <LaunchIcon />,
        rename: <EditIcon />,
        resize: <ZoomOutMapIcon />,
        update: <EditIcon />,
        insert: <AddBoxIcon />,
        insertIndicator: <AddchartIcon />,
        copyAnchor: <InsertLinkIcon />,
    };

    const handleOpen = (
        event:
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLElement>
            | null = null,
        level = 0,
        nestedOptions = options,
        persistAnchor = false,
    ) => {
        let target: HTMLElement | null = null;

        if (event) target = event.target as HTMLElement;

        if ((anchorPosition && target === null) || (persistAnchor && target)) {
            const targetPosition = target?.getBoundingClientRect();
            const anchor = anchorPosition || {
                top: targetPosition?.bottom,
                left: targetPosition?.left,
            };
            const div = document.createElement('div');
            div.style.position = 'absolute';
            div.style.visibility = 'hidden';
            div.style.top = `${anchor.top}px`;
            div.style.left = `${anchor.left}px`;
            if (!process.env.STORYBOOK) document.body.appendChild(div);
            target = div;
        }

        setAnchors((prevAnchors) => ({
            elements: prevAnchors.elements.map((element, index) =>
                index === level ? target : element,
            ),
            options: prevAnchors.options.map((element, index) =>
                index === level ? nestedOptions : element,
            ),
        }));

        setIsOpen(true);
    };

    const getId = (option: (typeof options)[0], index: number) => {
        return `${index}-${option.menuLevel}`;
    };

    React.useEffect(() => {
        if (triggerOpenEvent) {
            handleOpen(triggerOpenEvent);
        }

        if (anchorPosition) {
            handleOpen(null);
        }
    }, [triggerOpenEvent, anchorPosition]);

    const handleClose = (level: number) => {
        setAnchors((prevAnchors) => ({
            elements: prevAnchors.elements.map((element, index) =>
                index >= level ? null : element,
            ),
            options: prevAnchors.options.map((element, index) =>
                index >= level ? null : element,
            ),
        }));

        setIsOpen(false);
    };

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        if (onClickAway) onClickAway();

        if (event.target === buttonRef.current) {
            handleClose(0);
            return;
        }

        const optionWithoutSubMenu = anchors.elements.every(
            (element) => !event.composedPath().includes(element!),
        );

        if (optionWithoutSubMenu) {
            handleClose(0);
        }
    };

    const handleClickOption = (option: NestedMenuOptionT) => {
        if (!option.nestedOptions) {
            handleClose(0);
        }
        onOptionClick(option);
    };

    const handleMouseMove = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        option: NestedMenuOptionT,
        optIndex: number,
    ) => {
        let shouldComputeSubMenuOpenLogic = true;
        const submenu = document.querySelector(
            `#nested-menu-${option.menuLevel + 1}`,
        );

        const computeSubMenuLogic = () => {
            if (!mouseEntered.current[getId(option, optIndex)]) {
                mouseEntered.current[getId(option, optIndex)] = true;
                // Close all prior submenus if the mouse transitions from an option with a submenu to an option without a submenu.
                if (!option.nestedOptions) {
                    handleClose(option.menuLevel + 1);
                } else if (
                    // If the mouse moves from an option with a submenu to another option with a submenu, open the submenu of the current option and close the submenu of the previous option.
                    option.nestedOptions &&
                    anchors.options[option.menuLevel + 1] &&
                    !option.nestedOptions.every(
                        (val, i) =>
                            val.action ===
                            anchors.options[option.menuLevel + 1]?.[i].action,
                    )
                ) {
                    handleClose(option.menuLevel + 1);
                    handleOpen(
                        event,
                        option.menuLevel + 1,
                        option.nestedOptions,
                    );
                } else {
                    handleOpen(
                        event,
                        option.menuLevel + 1,
                        option.nestedOptions,
                    );
                }
            }
        };

        if (mouseLeftCordinates.current.length > 0 && submenu) {
            const { x, y, height } = submenu.getBoundingClientRect();

            // Form a virtual triangle using the left mouse coordinates and the top-left and bottom-left coordinates of the submenu. If the current mouse coordinates fall within this triangle, skip the submenu logic computation.
            // Check https://twitter.com/diegohaz/status/1283558204178407427 for more context.
            const currentMouseCoordinates = [event.clientX, -event.clientY];
            const virtualTriangleCordinates = [
                [x, -y],
                [x, -(y + height)],
                [
                    mouseLeftCordinates.current[0],
                    mouseLeftCordinates.current[1],
                ],
            ];

            if (
                pointInTriangle(
                    currentMouseCoordinates,
                    virtualTriangleCordinates,
                )
            ) {
                shouldComputeSubMenuOpenLogic = false;
                if (mouseIdleTimer.current) {
                    clearTimeout(mouseIdleTimer.current);
                }

                // if mouse is inside triangle and yet hasn't moved, we need to compute submenu logic after a delay
                mouseIdleTimer.current = setTimeout(() => {
                    computeSubMenuLogic();
                }, 50);
            }
        }

        if (shouldComputeSubMenuOpenLogic) {
            if (mouseIdleTimer.current) {
                clearTimeout(mouseIdleTimer.current);
            }
            computeSubMenuLogic();
        }
    };

    const handleMouseLeave = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        option: NestedMenuOptionT,
        optIndex: number,
    ) => {
        mouseLeftCordinates.current = [event.clientX, -event.clientY];

        if (mouseIdleTimer.current) {
            clearInterval(mouseIdleTimer.current);
        }
        mouseEntered.current[getId(option, optIndex)] = false;
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLLIElement>,
        option: NestedMenuOptionT,
    ) => {
        if (option.nestedOptions) {
            if (event.key === 'ArrowRight' || event.key === 'Enter') {
                handleOpen(event, option.menuLevel + 1, option.nestedOptions);
            }
        }
        if (event.key === 'ArrowLeft' && option.menuLevel > 0) {
            handleClose(option.menuLevel);
            anchors.elements[option.menuLevel]?.focus();
        }

        if (event.key === 'Escape') {
            handleClose(0);
        }
    };

    return (
        <>
            {button && !button.isIconButton && (
                <>
                    <Button
                        ref={buttonRef}
                        onClick={(event) => {
                            handleOpen(event);
                        }}
                    >
                        {button.text || ''}
                    </Button>
                </>
            )}

            {button && button.isIconButton && button.iconButton && (
                <IconButton
                    aria-owns={isOpen ? 'icon-menu' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => {
                        if (isOpen) {
                            handleClose(0);
                        } else {
                            handleOpen(event, 0, options, true);
                        }
                    }}
                    color={button.iconButton.color}
                    disabled={button.iconButton.disabled}
                    className={button.iconButton.className}
                    size={button.iconButton.small ? 'small' : 'medium'}
                    data-testid={button.iconButton['data-testid']}
                >
                    {button.iconButton.iconElement}
                </IconButton>
            )}

            {anchors.elements.map((anchorElement, index) =>
                anchorElement ? (
                    <Popper
                        open={Boolean(anchorElement)}
                        anchorEl={anchorElement}
                        key={`${anchorElement.innerText} menu`}
                        role={undefined}
                        placement={index > 0 ? 'right-start' : 'bottom-start'}
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: 'left top',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={handleClickAway}
                                    >
                                        <MenuList
                                            autoFocusItem={Boolean(
                                                anchorElement,
                                            )}
                                            id={`nested-menu-${index}`}
                                            aria-labelledby="nested-button"
                                        >
                                            {(anchors.options[index] ?? []).map(
                                                (option, optIndex) => (
                                                    <>
                                                        <MenuItem
                                                            key={option.action}
                                                            aria-haspopup={
                                                                !!option.nestedOptions ||
                                                                undefined
                                                            }
                                                            aria-expanded={
                                                                option.nestedOptions
                                                                    ? anchors.elements.some(
                                                                          (
                                                                              element,
                                                                          ) =>
                                                                              element?.innerText ===
                                                                              option.action,
                                                                      )
                                                                    : undefined
                                                            }
                                                            className={`e2e-chart-${option.action}-button`}
                                                            onClick={() =>
                                                                handleClickOption(
                                                                    option,
                                                                )
                                                            }
                                                            onMouseMove={(
                                                                event,
                                                            ) =>
                                                                handleMouseMove(
                                                                    event,
                                                                    option,
                                                                    optIndex,
                                                                )
                                                            }
                                                            onMouseLeave={(
                                                                event,
                                                            ) =>
                                                                handleMouseLeave(
                                                                    event,
                                                                    option,
                                                                    optIndex,
                                                                )
                                                            }
                                                            onKeyDown={(
                                                                event,
                                                            ) =>
                                                                handleKeyDown(
                                                                    event,
                                                                    option,
                                                                )
                                                            }
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-start',
                                                                    width: '100%',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    {
                                                                        icons[
                                                                            option
                                                                                .action
                                                                        ]
                                                                    }
                                                                </ListItemIcon>
                                                                <Typography>
                                                                    {capitalize(
                                                                        t(
                                                                            `chart.${option.action}`,
                                                                        ),
                                                                    )}
                                                                </Typography>
                                                                {option.nestedOptions ? (
                                                                    <ChevronRight
                                                                        fontSize="small"
                                                                        sx={{
                                                                            marginLeft:
                                                                                'auto',
                                                                        }}
                                                                    />
                                                                ) : null}
                                                            </Box>
                                                        </MenuItem>
                                                        {option.separator && (
                                                            <Divider key="first-divider" />
                                                        )}
                                                    </>
                                                ),
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                ) : null,
            )}
        </>
    );
};

export default NestedMenu;
