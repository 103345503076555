import React from 'react';
// TODO: import modules as part of Epic "Appliquer les bonnes pratiques AgGrid" (Shortcut ID: 25606)
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import 'ag-grid-enterprise';
import i18n from 'i18n';
import { styled } from '@mui/material';
import fr from './fr';
import en from './en';
import './style.scss';

const StyAgGrid = styled(AgGridReact, { name: 'StyAgGrid' })(({ theme }) => ({
    '--color-primary': theme.palette.primary.main,
    '--color-primary-light': theme.palette.primary.light,
    '--ag-chip-background-color': '#FFFFFF',
    '--ag-checkbox-checked-color': theme.palette.primary.main,
}));

const AgGrid = (
    props: AgGridReactProps & {
        className?: string;
        children?: React.ReactNode;
    },
) => {
    return (
        // @ts-expect-error // TODO: use theme prop as part of Epic "Appliquer les bonnes pratiques AgGrid" (Shortcut ID: 25606)
        <StyAgGrid
            localeText={i18n.language === 'fr' ? fr : en}
            floatingFiltersHeight={56} // it can be overriden by props
            suppressFieldDotNotation
            suppressAggFuncInHeader
            suppressCellFocus // we don't need keyboard navigation and we don't want it to work for the BL id group column
            paginationPageSizeSelector={false}
            animateRows={false}
            columnMenu="legacy"
            {...props}
        />
    );
};

export default AgGrid;
