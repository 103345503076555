import { Popper, PopperProps } from '@mui/material';
import React from 'react';

//hack to make popper always show at bottom
//https://stackoverflow.com/questions/63881966/unable-to-fix-popper-placement-in-autocomplete
const modifiers = [
    {
        name: 'flip',
        options: {
            fallbackPlacements: [],
        },
    },
];

export default function AutocompletePopper(
    props: Omit<PopperProps, 'modifiers' | 'popperOptions'>,
): React.ReactElement {
    return (
        <Popper
            {...props}
            modifiers={modifiers}
            popperOptions={{
                placement: 'bottom',
            }}
            disablePortal
        />
    );
}
