import React from 'react';

import Typography from '@mui/material/Typography';
import { styled, SxProps } from '@mui/system';
import NoData from 'assets/no_data.svg';
import { useTranslation } from 'react-i18next';

const StyNoDataWrapper = styled('div', { name: 'StyNoDataWrapper' })(
    ({ theme }) => ({
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'column',
    }),
);

const NoDataState = ({
    message = undefined,
    sx = {},
}: {
    message?: string;
    sx?: SxProps;
}): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <StyNoDataWrapper sx={sx}>
            <NoData
                viewBox="0 0 126 125"
                width="252"
                height="250"
                role="img"
                aria-label="no data"
                preserveAspectRatio="xMidYMid meet"
            />

            <Typography variant="h2">
                {t(message ?? 'common.noData')}
            </Typography>
        </StyNoDataWrapper>
    );
};

export default NoDataState;
