import { EnrichedUserT } from 'business/definitions';
import { useQuery, UseQueryResult } from 'react-query';
import { HttpError, RQAPICallWithData } from 'store/APIClient';

export type SuggestedUsersFilter = (users: EnrichedUserT[]) => EnrichedUserT[];

export const useGetSuggestedUsers = (
    filterUsers: SuggestedUsersFilter,
    enabled: boolean = true,
): UseQueryResult<EnrichedUserT[], HttpError> =>
    useQuery<EnrichedUserT[], HttpError>(
        'users',
        async () => {
            const response = await RQAPICallWithData<EnrichedUserT[]>({
                endpoint: 'auth/users',
                method: 'GET',
            });
            return filterUsers(response.content.data);
        },
        { enabled },
    );
