import { styled } from '@mui/material';

const StyActionContainer = styled('div', {
    name: 'StyActionContainer',
})({
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginRight: '10px',
    width: '100%',
});

export default StyActionContainer;
